import React, { Fragment as div } from "react";


function PrivacyPolicyPage() {
    return (
        <div className="m-10 leading-relaxed">
            <h1 className="text-2xl font-semibold" id="privacy-policy">Privacy Policy</h1>
            <h2 className="text-xl font-semibold mt-3 mb-3" id="what-we-collect">What we collect</h2>
            <p>We only collect the information that we actually need. Some of that is information that you actively give us when you sign up for an account, ask for customer support.
                We store your name and contact information, but we don&#39;t store credit card numbers (except with your permission and in one of our secured payment gateways).</p>
            <p>When you visit one of our websites or use our software, we automatically log some basic information like how you got to the site, where you navigated within it, and what features and settings you use.
                We use this information to improve our websites and services and to drive new product development.</p>
            <h2 className="text-xl font-semibold mt-3 mb-3" id="what-we-do-with-your-information">What we do with your information</h2>
            <p className="mb-2">We use your information to provide the services you&#39;ve requested, create and maintain your accounts, and keep an eye out for unauthorized activity on your accounts.
                We also use it to communicate with you about the products you&#39;re currently using, your customer support requests, new products you may like, chances for you to give us feedback, and policy updates.
                We analyze the information we collect to understand user needs and to improve our websites and services.</p>
            <p className="mb-2">Information about your customers or employees, you are entrusting that data to us for processing.
                If you give permission to our mobile app to access your contacts and photo library, you are entrusting data to us.
                The data you entrust to us for processing is called service data.</p>
            <p>You own your service data. We protect it, limit access to it, and only process it according to your instructions.
                You may access it, share it through third-party integrations, and request that we export or delete it.</p>
            <p>We hold the data in your account as long as you choose to use our services.
                After you terminate your account, your data will be automatically deleted from our active database within 6 months and from our backups within 3 months after that.</p>
            <h2 className="text-xl font-semibold mt-3 mb-3" id="our-privacy-commitment">Our privacy commitment</h2>
            <p>Pickmystylist has never sold your information to someone else for advertising, or made money by showing you other people&#39;s ads, and we never will.
                This policy tells you what information we collect from you, and what we do with it.</p>
        </div>
    );
}

export default PrivacyPolicyPage;